const data = {
  name: "OfferGrid",
  props: {
    title: "Similar vacancies",
    offerCard: {
      image: {
        src:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/offer_hole.png",
        alt: "",
      },
      cta: {
        icon: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
          alt: "",
        },
        title: "Read offer",
      },
    },
    bottomCta: {
      icon: {
        src:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
        alt: "",
      },
      hrefTo: "/join-us/job-openings",
      title: "View all openings",
    },
    offers: [
      {
        title: "Lead Game Designer",
        roles: [6],
        teams: [6],
        hrefTo: "",
      },
      {
        title: "VP of monetization",
        roles: [6],
        teams: [6],
        hrefTo: "",
      },
      {
        title: "2D Animator",
        roles: [6],
        teams: [6],
        hrefTo: "",
      },
      {
        title: "Lead artist",
        roles: [6],
        teams: [6],
        hrefTo: "",
      },
    ],
  },
};
export default data;
