import Menu from "./sections/Menu";
import Footer from "./sections/Footer";

const data = {
  meta: {
    title: "Socialpoint Support",
    description: "Find support for your favourite Socialpoint game",
    keywords:
      "socialpoint, social point, socialpoint support, social point games support, dragon city support, monster legends support, tasty town support, world chef support, word life support, free to play games support, helpshift",
  },
  menu: Menu(2),
  breadcrumb: {
    color: "white",
    arrow:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrow-right.svg",
    items: [
      {
        title: "home",
        hrefTo: "/",
      },
      {
        title: "support",
        hrefTo: "/support",
      },
    ],
  },
  components: [
    {
      name: "Wrapper",
      props: {
        backgroundColor: "purple",
      },
      components: [
        {
          name: "Container",
          props: {
            className: "pt-40 md:pt-64",
          },
          components: [
            {
              name: "Title1",
              props: {
                text: "Find support\nfor your favourite game",
                color: "white",
              },
            },
          ],
        },
        {
          name: "Games",
          props: {
            title: null,
            items: [
              {
                id: "1",
                background:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/games/Vertical_Card_Top-Troops.jpg",
                logo:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logos/Top-Troops_Logo.png",
                hrefTo: "https://socialpoint.helpshift.com/a/top-troops/",
                title: "Top Troops",
                button: {
                  hrefTo: "https://socialpoint.helpshift.com/a/top-troops/",
                  title: "Go to Support",
                  color: "purple",
                  hoverColor: "white",
                  backgroundColor: "white",
                },
              },
              {
                id: "2",
                background:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/games/Vertical_Card_DC.jpg",
                logo:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logos/DC-logo-2022-2.png",
                hrefTo: "https://socialpoint.helpshift.com/a/dragon-city/",
                title: "Dragon City",
                button: {
                  hrefTo: "https://socialpoint.helpshift.com/a/dragon-city/",
                  title: "Go to Support",
                  color: "purple",
                  hoverColor: "white",
                  backgroundColor: "white",
                },
              },
              {
                id: "3",
                background:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/two-dots/Vertical_Card_TD.jpeg",
                logo:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/two-dots/TD-logo.png",
                hrefTo:
                  "https://dots.helpshift.com/hc/en/3-two-dots/",
                title: "Two Dots",
                button: {
                  hrefTo:
                  "https://dots.helpshift.com/hc/en/3-two-dots/",
                  title: "Go to Support",
                  color: "purple",
                  hoverColor: "white",
                  backgroundColor: "white",
                },
              },
              {
                id: "4",
                background:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/games/Vertical_Card_ML.jpg",
                logo:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logos/ML-logo-2021.png",
                hrefTo:
                  "https://socialpoint.helpshift.com/a/monster-legends-mobile/",
                title: "Monster Legends",
                button: {
                  hrefTo:
                    "https://socialpoint.helpshift.com/a/monster-legends-mobile/",
                  title: "Go to Support",
                  color: "purple",
                  hoverColor: "white",
                  backgroundColor: "white",
                },
              },
              {
                id: "5",
                background:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/games/Vertical_Card_WL.jpg",
                logo:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logos/WL-logo-2021.png",
                hrefTo: "https://socialpoint.helpshift.com/a/word-life/",
                title: "Word Life",
                button: {
                  hrefTo: "https://socialpoint.helpshift.com/a/word-life/",
                  title: "Go to Support",
                  color: "purple",
                  hoverColor: "white",
                  backgroundColor: "white",
                },
              },
              {
                id: "6",
                background:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/games/Vertical_Card_TT.jpg",
                logo:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logos/TT-logo.png",
                hrefTo: "https://socialpoint.helpshift.com/a/tasty-town/",
                title: "Tasty Town",
                button: {
                  hrefTo: "https://socialpoint.helpshift.com/a/tasty-town/",
                  title: "Go to Support",
                  color: "purple",
                  hoverColor: "purple",
                  backgroundColor: "white",
                },
              },
              {
                id: "7",
                background:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/games/Vertical_Card_WC.jpg",
                logo:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logos/WC-logo.png",
                hrefTo: "https://socialpoint.helpshift.com/a/world-chef/",
                title: "World Chef",
                button: {
                  hrefTo: "https://socialpoint.helpshift.com/a/world-chef/",
                  title: "Go to Support",
                  color: "purple",
                  hoverColor: "white",
                  backgroundColor: "white",
                },
              },
            ],
          },
        },
        {
          name: "Separator",
          props: {
            topColor: "purple",
            bottomColor: "gray",
          },
        },
      ],
    },/*
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        /*{
          name: "GameReel",
          props: {
            title: {
              text: "Game reel",
            },
            video: {
              src: "https://vimeo.com/375609316/caf8132bac",
              poster:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/support-video-thumbnail.jpg",
              player: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/player-play.svg",
                backgroundColor: "white",
                alt: "",
                color: "purple",
              },
            },
          },
        },
        Footer,
      ],
    },*/
  ],
};

export default data;
