const data = {
  name: "Benefits",
  props: {
    image: {
      src:
        "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/join-us/benefits/pool-party.jpg",
      alt: ""
    },
    title: `Enjoy our benefits!`,
    htmlText: `<p>
                We want to contribute to your wellbeing and make your life easy.
                But above all, we want you to have fun. Lots of it.
               <p>
               <p>
                Being a Socialpointer is highly rewarding for many reasons.
                The atmosphere, coworkers and resources are just the icing on the cake!
                If you are thinking of joining the family,
                there are additional perks that will most deﬁnitely tip the scale.
                </p>`,
    cta: {
      hrefTo: "/join-us/benefits",
      title: "Know more"
    },
    advantages: {
      items: [
        {
          icon: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/benefit_health.svg",
            alt: ""
          },
          title: "Private medical insurance and physiotherapy"
        },
        {
          icon: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/benefit_meal.svg",
            alt: ""
          },
          title: "Free meals and on-site gym"
        },
        {
          icon: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/benefit_gaming.svg",
            alt: ""
          },
          title: "Hairdresser, social events and gaming area"
        },
        {
          icon: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/benefit_language.svg",
            alt: ""
          },
          title: "Training and language courses"
        },
        // ---- temporarily removing "Newbies program" from benefit
        // {
        //   icon: {
        //     src:
        //       "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/benefit_newbies.svg",
        //     alt: "",
        //   },
        //   title: "Newbies program",
        // },
        {
          icon: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/benefit_vacation.svg",
            alt: ""
          },
          title: "Additional vacation days"
        }
      ],
      highlight: {
        text:
          "The list of advantages goes on and on. Come aboard and find out for yourself.",
        cta: {
          title: "View all",
          icon: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
            alt: ""
          },
          hrefTo: "/join-us/benefits"
        }
      }
    }
  }
};
export default data;
