const data = {
  name: "JumpAboard",
  props: {
    title: "Jump aboard",
    htmlText: `<p>You have the talent, we have the playground. Socialpoint is always
                    looking for creative and passionate people.
                    Join our team and be part of something amazing.</p>`,
    cta: {
      title: "View all openings",
      hrefTo: "/join-us/careers",
    },
    image: {
      src:
        "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/jump_aboard/jump_aboard.png",
      alt: "",
    },
    itemsTitle: "Latest offers",
    dragInstructions: {
      text: "Drag to view more",
      icon: {
        src:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/gallery-right-arrow.svg",
        alt: "",
      },
    },
    items: [
      {
        id: "1",
        title: "Creative Marketing Director (Gaming)",
        cta: {
          title: "Read more",
          icon: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
            alt: "",
          },
          hrefTo: "#",
        },
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/offer_hole.png",
          alt: "",
        },
      },
      {
        id: "2",
        title: "Creative Marketing Director (Gaming)",
        cta: {
          title: "Read more",
          icon: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
            alt: "",
          },
          hrefTo: "#",
        },
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/offer_hole.png",
          alt: "",
        },
      },
      {
        id: "3",
        title: "Creative Marketing Director (Gaming)",
        cta: {
          title: "Read more",
          icon: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
            alt: "",
          },
          hrefTo: "#",
        },
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/offer_hole.png",
          alt: "",
        },
      },
      {
        id: "4",
        title: "Creative Marketing Director (Gaming)",
        cta: {
          title: "Read more",
          icon: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
            alt: "",
          },
          hrefTo: "#",
        },
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/offer_hole.png",
          alt: "",
        },
      },
      {
        id: "5",
        title: "Creative Marketing Director (Gaming)",
        cta: {
          title: "Read more",
          icon: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
            alt: "",
          },
          hrefTo: "#",
        },
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/offer_hole.png",
          alt: "",
        },
      },
    ],
  },
};
export default data;
