import menu from "./sections/Menu";
import meta from "./sections/Meta";
import Footer from "./sections/Footer";

const data = {
  meta,
  menu: menu(),
  breadcrumb: {
    color: "purple",
    arrow:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrow-right.svg",
    items: [
      { title: "home", hrefTo: "/" },
      { title: "legal", hrefTo: "/privacy-policy" },
    ],
  },
  components: [
    {
      name: "Wrapper",
      props: {
        backgroundColor: "purple",
      },
      components: [
        {
          name: "LegalRouting",
          props: {
            title: "place-holder",
            routes: [
              {
                company: "socialpoint",
                caption: {
                  en: "ERROR",
                },
                url: {
                  langMap: {
                    en: "ERROR",
                  },
                  buildingPattern: ["https://www.socialpoint.es/"],
                },
              },
            ],
          },
        },
      ],
    },
    Footer,
  ],
};

export default data;
