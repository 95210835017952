const data = {
  name: "Openings",
  props: {
    filters: {
      roles: {
        placeholder: "All roles",
        icon: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrow-down.svg",
          alt: "",
        },
      },
      teams: {
        placeholder: "All teams",
        icon: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrow-down.svg",
          alt: "",
        },
      },
      search: {
        icon: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/search.svg",
          alt: "",
        },
      },
    },
    filterTitle: "Filter Content",
    offers: {
      teams: [
        { label: "Analytics", value: 1 },
        { label: "Art", value: 2 },
        { label: "Backend", value: 3 },
        { label: "Customer Support", value: 4 },
        { label: "Game Design", value: 5 },
        { label: "Marketing", value: 6 },
        { label: "Mobile", value: 7 },
        { label: "Product", value: 8 },
        { label: "System", value: 9 },
      ],
      items: [
        {
          title: "Lead Game Designer",
          teams: [1],
          hrefTo: "",
        },
        {
          title: "Lead Game Designer",
          teams: [3],
          hrefTo: "",
        },
        {
          title: "Lead Game Designer",
          teams: [2],
          hrefTo: "",
        },
      ],
      meta: {
        cta: {
          icon: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
            alt: "",
          },
          title: "Read More",
        },
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/offer_hole.png",
          alt: "",
        },
      },
    },
    activeOffersText: "Current vacancies",
    noResultsText: "Vacancies not available",
  },
};
export default data;
