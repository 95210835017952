import Footer from "./sections/Footer";
import menu from "./sections/Menu";
import Openings from "./sections/Openings";

const data = {
  meta: {
    title: "Socialpoint Job Openings",
    description:
      "Are you ready for a new adventure? Great games start with great people. Check out all our latest vacancies using the filters below!",
    keywords:
      "socialpoint, job opening, socialpoint vacancies, socialpoint job openings, vacancies in Barcelona, mobile games job openings, free to play vacancies, free to play job openings",
  },
  menu: menu(4),
  breadcrumb: {
    color: "purple",
    arrow:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrow-right.svg",
    items: [
      { title: "home", hrefTo: "/" },
      { title: "join us", hrefTo: "/join-us" },
      { title: "job openings", hrefTo: "/join-us/job-openings" },
    ],
  },
  components: [
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        {
          name: "JobOpenings",
          props: {
            title: "Job Openings",
            htmlText: `<p>Are you ready for a new adventure? Great games start with great people. Check out all our latest vacancies using  the filters below!</p>`,
            image: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/job_openings/job_openings.png",
              alt: "",
            },
          },
        },
        Openings,
        {
          name: "Separator",
          props: {
            topColor: "gray",
            bottomColor: "gray",
          },
        },
        Footer,
      ],
    },
  ],
};

export default data;
