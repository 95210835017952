const data = {
  name: "QuoteWithImage",
  props: {
    image: {
      src:
        "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/quote_with_image/quote_with_image.png",
      alt: "",
    },
    quote: {
      icon: {
        src:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/quotes.svg",
        alt: "",
      },
      htmlText: "---",
      image: {
        src: "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/girl.png",
        alt: "",
      },
    },
    name: "---",
    position: "---",
    city: "Barcelona",
  },
};
export default data;
