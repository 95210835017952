const data = {
  name: "LifeAtSocialpointSimple",
  props: {
    title: `Life in Barcelona`,
    htmlText: `It’s easy to see why Barcelona is fast-becoming Europe’s newest tech hub. Come check out how we have – and create – fun in this sunny city.`,
    cta: {
      title: "Know more",
      hrefTo: "/join-us/life-in-barcelona"
    },
    image1: {
      src:
        "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/about/life-in-barcelona/image_1.jpg",
      alt: ""
    },
    image2: {
      src:
        "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/about/life-in-barcelona/image_2.jpg",
      alt: ""
    }
  }
};
export default data;
