import menu from "./sections/Menu";
import Footer from "./sections/Footer";
import OfficeTour from "./sections/OfficeTour";
import Benefits from "./sections/Benefits";
import JumpAboard from "./sections/JumpAboard";
import Relocate from "./sections/Relocate";
import video from "../images/dynamic-header/life-in-barcelona.mp4";
import poster from "../images/dynamic-header/life-in-barcelona.jpg";

const data = {
  meta: {
    title: "Socialpoint - Life in Barcelona",
    description:
      "A location where startups blossom, the trendy district of Poblenou houses the 22@ project – and Socialpoint. Our designer offices are full of natural light, have 360º views of Barcelona and look upon Mount Tibidabo, La Sagrada Familia… and even the sea.",
    keywords:
      "socialpoint, social point, barcelona, Barcelona life, digital talent, digital hub, socialpoint Barcelona"
  },
  menu: menu(4),
  components: [
    {
      name: "SectionHeader",
      props: {
        title: "Life in Barcelona",
        image:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/life_in_barcelona/life_in_barcelona_header.jpg",
        imageMobile: {
          url:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/life_in_barcelona/life_in_barcelona_header_portrait.jpg"
        },
        video: {
          src: video,
          poster: poster,
          player: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/player-play.svg",
            alt: ""
          },
          fullWidth: true,
          videoAsHeader: true
        }
      }
    },
    {
      name: "DigitalTalentHub",
      props: {
        top: {
          title: "Barcelona: a hub for digital talent",
          image: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/life-in-barcelona/digital-talent-hub/image_1.jpg",
            alt: ""
          },
          htmlText: `
          <p>
            Barcelona is one of the most sought after destinations for tourists and expats
            from all around the world. Lately, its global presence has acquired a bigger international relevance.
           </p>

          <p>
            Nowadays, this techy city appears at the top of more than a few rankings.
            It’s been classified as one of the best cities in the world for both
            talent attraction and as a technologically future-proof city.
            In Europe, it’s considered as one of the best startup hubs, the most innovative cities,
            the regions with the most people working in high tech jobs, and has one of the highest
            concentration of innovation centres in the south… you name it!
          </p>

          <p>
            A location where startups blossom, the trendy district of Poblenou houses the 22@ project
            – and Socialpoint. Our designer offices are full of natural light, have 360º views of
            Barcelona and look upon Mount Tibidabo, La Sagrada Familia… and even the sea.
          </p>
          `,
          cta: {
            title: "View all openings",
            hrefTo: "/join-us/careers"
          }
        },
        middle: {
          image1: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/life-in-barcelona/digital-talent-hub/image_2.jpg",
            alt: ""
          },
          image2: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/life_in_barcelona/life_in_barcelona_3.png",
            alt: ""
          },
          htmlText: `
          <h4>Location, weather and things to do</h4>
          <p>
          With the Mediterranean sea on one side, and the mountain range on the other, you’ll find both futuristic skyscrapers and narrow medieval streets in this city of contrasts.
          </p>
          <p>
          You won’t run out of plans for the weekend, whether you want to explore its vibrant nightlife and visit the city’s main cultural attractions, or discover the breathtaking landscapes around the metropolis. Collserola, Montserrat and Montseny mountains, fishing villages such as Sitges or Tossa de Mar, medieval villages such as Pals, and astonishing coves and beaches like the ones in Calella de Palafrugell... all await your visit.
          </p>
          <p>
          The sun will most likely brighten up your journey. The Mediterranean climate is pleasant all year long, with warm summers and mild temperatures throughout the other seasons.
          </p>
          `
        },
        bottom: {
          htmlText: `
          <h4>A melting pot</h4>
          <p>
          Barcelona is a true melting pot where people with different backgrounds, languages and cultures respectfully coexist. No wonder Barcelonians are known as cosmopolitan, welcoming, and open-minded people.
          </p>
          <p>
          Almost half of Barcelona’s residents are expats – over 30% of IT professionals. They choose Barcelona because of its high quality of life (best in Spain together with Madrid), education and healthcare, along with the professional opportunities, cultural heritage and gastronomy.
          </p>
          <p>
          Beauty, tech, culture and opportunity are just a few of the thousand pieces that compose the colorful and unique mosaic of the city. No matter what you’re looking for, Barcelona has it.
          </p>
          `,
          image: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/life-in-barcelona/digital-talent-hub/image_3.jpg",
            alt: ""
          }
        }
      }
    },
    {
      name: "FullWidthImage",
      props: {
        src:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/life-in-barcelona/full-width.jpg",
        alt: "The people"
      }
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray"
      },
      // components: [OfficeTour]
      components: [
        {
          name: "Video",
          props: {
            src: "https://vimeo.com/980388448",
            poster:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/join-us/office-tour-thumbnail.jpg",
            player: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/player-play.svg",
              backgroundColor: "purple",
              alt: "",
              color: "white"
            }
          }
        }
      ]
    },
    {
      name: "Separator",
      props: {
        topColor: "gray",
        bottomColor: "purple-light"
      }
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "purple-light"
      },
      components: [Relocate]
    },
    {
      name: "Separator",
      props: {
        topColor: "purple-light",
        bottomColor: "purple"
      }
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "purple"
      },
      components: [Benefits]
    },
    {
      name: "Separator",
      props: {
        topColor: "purple",
        bottomColor: "gray"
      }
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray"
      },
      components: [JumpAboard]
    },
    Footer
  ]
};

export default data;
