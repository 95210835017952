const data = {
  name: "JobOpportunities",
  props: {
    layout: {
      type: "left",
      imageOrientation: "vertical",
      imagePosition: "bottom",
    },
    title: "Check out all other job opportunities",
    htmlText: `
      <p>Haven’t found what you’re looking for?<br/>Click the button below and view all of our  latest job opportunities!</p>
    `,
    cta: {
      title: "View all openings",
      hrefTo: "/join-us/careers",
    },
    image: {
      src:
        "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/job_opportunities/job_opportunities_1.png",
      alt: "",
    },
  },
};
export default data;
