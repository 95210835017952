import menu from "./sections/Menu";
import Footer from "./sections/Footer";

const data = {
  meta: {
    title: "Socialpoint Newsletter",
    description: "Stay updated with the latest news on all our games.",
    keywords:
      "socialpoint, social point, SocialPointers, socialpoint newsletter, socialpoint promotional messages, socialpoint latest news, socialpoint sign up, Take Two Interactive, T2, Take 2 Interactive",
  },
  menu: menu(4),
  breadcrumb: {
    color: "purple",
    arrow:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrow-right.svg",
    items: [
      { title: "home", hrefTo: "/" },
      { title: "newsletter", hrefTo: "/newsletter" },
    ],
  },
  components: [
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        {
          name: "StayUpdated",
          props: {
            title: "Stay updated with the latest news on all our games",
            image: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/newsletter/newsletter.png",
              alt: "",
            },
            form: {
              fields: [
                {
                  label: "Email",
                  type: "email",
                },
                {
                  label: "Nickname",
                  type: "text",
                },
              ],
              color: "green",
              htmlText: `
          <p>
          I would like to receive news and promotional messages from Socialpoint S.L and affiliates. 
          I can unsubscribe at any time by emailling 
          <a href="mailto:unsubscribe@socialpoint.es">
            <u>unsubscribe@socialpoint.es</u>
          </a> 
          with “unsubscribe” in the subject line.
          </p>
          <p>
          I confirm that I have read and understand Socialpoint S.L’s <u><a href="https://legal.socialpoint.es/privacy">
          Privacy Policy</a></u> & <u><a href="https://legal.socialpoint.es/tos">Terms of Service.</a></u></p>
          `,
              cta: {
                color: "red",
                resultSuccess: "Your info was sent",
                resultError: "There was a problem with your info",
                loading: "Sending...",
                title: "Submit",
              },
              endpoint: `${process.env.GATSBY_STRAPI_API_URL}/newsletter/subscribe`,
            },
          },
        },
      ],
    },
    Footer,
  ],
};

export default data;
