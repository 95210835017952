import about from "./about";
import adpartners from "./adpartners";
import apply from "./apply";
import benefits from "./benefits";
import careers from "./careers";
import department from "./department";
import games from "./games";
import home from "./home";
import job from "./job";
import jobalerts from "./jobalerts";
import jobopenings from "./jobopenings";
import joinus from "./joinus";
import legalrouting from "./legalrouting.js";
import lifeinbarcelona from "./lifeinbarcelona";
import meettheteam from "./meettheteam";
import newsletter from "./newsletter";
import relocate from "./relocate";
import support from "./support";

export default {
  about,
  adpartners,
  apply,
  benefits,
  careers,
  department,
  games,
  home,
  job,
  jobalerts,
  jobopenings,
  joinus,
  legalrouting,
  lifeinbarcelona,
  meettheteam,
  newsletter,
  relocate,
  support,
};
