import menu from "./sections/Menu";
import meta from "./sections/Meta";
import Footer from "./sections/Footer";
import OfferGrid from "./sections/OfferGrid";
import OfficeTour from "./sections/OfficeTour";
import LifeAtSocialpoint from "./sections/LifeAtSocialpoint";

const data = {
  meta,
  menu: menu(4),
  breadcrumb: {
    color: "purple",
    arrow:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrow-right.svg",
    items: [
      { title: "home", hrefTo: "/" },
      { title: "join us", hrefTo: "/join-us" },
      { title: "job openings", hrefTo: "/join-us/job-openings" },
    ],
  },
  components: [
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        {
          name: "OfferDetail",
          props: {
            title: "---",
            htmlText: "---",
            shareText: "SHARE",
            jobId: -1,
            cta: {
              title: "Apply now",
              hrefTo: "",
            },
          },
        },
        OfferGrid,
        {
          name: "Separator",
          props: {
            topColor: "gray",
            bottomColor: "green",
          },
        },
      ],
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "green",
      },
      components: [LifeAtSocialpoint],
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        {
          name: "Separator",
          props: {
            topColor: "green",
            bottomColor: "gray",
            image: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/home/home_1.png",
              alt: "",
              position: "left",
              top: "-top-40 md:-top-500",
            },
          },
        },
        OfficeTour,
        Footer,
      ],
    },
  ],
};

export default data;
