import Footer from "./sections/Footer";
import Menu from "./sections/Menu";
import Benefits from "./sections/Benefits";
import JumpAboard from "./sections/JumpAboard";
import LifeAtSocialpointSimple from "./sections/LifeAtSocialpointSimple";

const data = {
  meta: {
    title: "Socialpoint - Relocate to Barcelona",
    description:
      "Okay, so you're ready to seize an amazing professional opportunity, and have lots of fun at our HQ in Barcelona.Yet, you're worried about relocating? Fear no more; we are here to help!",
    keywords:
      "socialpoint, relocate, barcelona, professional barcelona, relocating family barcelona, work in Barcelona, socialpoint Barcelona"
  },
  menu: Menu(4),
  breadcrumb: {
    color: "purple",
    arrow:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrow-right.svg",
    items: [
      { title: "home", hrefTo: "/" },
      { title: "join us", hrefTo: "/join-us" },
      { title: "relocate", hrefTo: "/relocate" }
    ]
  },
  components: [
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray"
      },
      components: [
        {
          name: `WeWillHelpYou`,
          props: {
            top: {
              title: `We'll help you relocate`,
              subtitle: `Don't worry, be happy`,
              image: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/relocate/relocate_1.png",
                alt: ""
              },
              htmlText: `
                <p>
                  Okay, so you’re ready to seize an amazing professional opportunity, and have lots of fun at our HQ in Barcelona.
                  Yet, you’re worried about  relocating?
                </p>
                <br>
                <p>
                  Fear no more; we are here to help!
                </p>
              `
            },
            middle: {
              htmlText: `
              <h4>Find your new home...</h4>
              <p>
              We know ﬁnding a new place is key to feeling at home in
              a new city. So, the search will become your top priority…
              and ours.
                </p>
                <p>
              We’ll listen to your family’s needs and priorities, and inform
              you when we ﬁnd something that suits. We’ll help in preparing
              an agenda with the right properties for you, accompanying
              you every step of the way.
              </p>
              `,
              image: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/relocate/find-your-home/middle.jpg",
                alt: ""
              },
              cta: {
                title: "Check openings",
                hrefTo: "/join-us/careers"
              }
            },
            bottom: {
              htmlText: `
              <h4>...and everything else you need!</h4>
              <p>
              We will also support you with the necessary administrative procedures so that, as a new member of the community, you enjoy the same rights and public beneﬁts as all Barcelonians.
              </p>
              <p>
              From opening a local bank account to ﬁnding the right school for your kids, everything is easier with an expert on your side.
              </p>
              `,
              image1: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/relocate/find-your-home/bottom.jpg",
                alt: ""
              },
              image2: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/relocate/relocate_4.png",
                alt: ""
              }
            }
          }
        }
      ]
    },
    {
      name: "FullWidthImage",
      props: {
        src:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/relocate/full-width.jpg",
        alt: "The people"
      }
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "blue-light"
      },
      components: [LifeAtSocialpointSimple]
    },
    {
      name: "Separator",
      props: {
        topColor: "blue-light",
        bottomColor: "purple"
      }
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "purple"
      },
      components: [Benefits]
    },
    {
      name: "Separator",
      props: {
        topColor: "purple",
        bottomColor: "gray"
      }
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray"
      },
      components: [JumpAboard]
    },
    {
      name: "FullWidthImage",
      props: {
        src:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/relocate/relocate_large.jpg",
        alt: "Team"
      }
    },
    Footer
  ]
};

export default data;
