import Menu from "./sections/Menu";
import Footer from "./sections/Footer";
import GamesInfo from "./sections/GamesInfo";

const data = {
  meta: {
    title: "Socialpoint Games",
    description:
      "Our mega hits entertain millions of fans every day. Not one of them yet? Try our games and get ready to be amazed!",
    keywords:
      "socialpoint, social point, socialpoint games, dragon city, monster legends, tasty town, world chef, word life, free to play games, social games",
  },
  menu: Menu(1),
  breadcrumb: {
    color: "purple",
    arrow:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrow-right.svg",
    items: [
      { title: "home", hrefTo: "/" },
      { title: "games", hrefTo: "/games" },
    ],
  },
  components: [
    {
      name: "Wrapper",
      props: {
        backgroundColor: "purple",
      },
      components: [
        {
          name: "Games",
          props: {
            showTitle: false,
            items: GamesInfo,
          },
        },
          {
          name: "GameReel",
          props: {
            title: {
              text: "Take a Look to Our Game Reel!",
            },
            video: {
              src: "https://vimeo.com/958282616",
              poster:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/support-video-thumbnail.jpg",
              player: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/player-play.svg",
                backgroundColor: "white",
                alt: "",
                color: "purple",
              },
            },
          },
        },
        Footer,
      ],
    },
  ],
};

export default data;
