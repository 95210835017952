import menu from "./sections/Menu";
import meta from "./sections/Meta";
import footer from "./sections/Footer";
import OfferGrid from "./sections/OfferGrid";

const data = {
  meta,
  menu: menu(4),
  breadcrumb: {
    color: "purple",
    arrow:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrow-right.svg",
    items: [
      { title: "home", hrefTo: "/" },
      { title: "join us", hrefTo: "/join-us" },
      { title: "job openings", hrefTo: "/join-us/careers" },
    ],
  },
  components: [
    {
      name: "Wrapper",
      props: {
        backgroundColor: "blue-light",
      },
      components: [
        {
          name: "OfferApplication",
          props: {
            jobid: -1,
            endpoint: `${process.env.GATSBY_STRAPI_API_URL}/greenhouse/apply`,
            title: "---",
            subtitle: "Application Form",
            htmlText1: `Thanks for considering a career at Socialpoint.
            Please fill out the following form, attaching any other relevant
            information.`,
            htmlText2: `
            <p class="italic bold">By clicking the “Send” button you expressly make the following representations and warranties and give your consents as described below:</p>
            <p class="italic">Socialpoint collects your personal data for the purposes of managing Socialpoint’s recruitment related activities as well as for organizational planning purposes globally. Consequently, Socialpoint may use your personal data in relation to the evaluation and selection of applicants including for example setting up and conducting interviews and tests, evaluating and assessing the results thereto and as is otherwise needed in the recruitment processes including the final recruitment.</p>`,
            linkedinUrl: "",
            cta: {
              attachment: "Choose files",
              selected: "Files selected",
              submit: "Accept and send",
              noFiles: "No files selected",
              resultSuccess: "Your application was sent",
              resultError: "There was a problem with your application",
              loading: "Sending...",
            },
            image: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/apply/apply.png",
              alt: "",
            },
          },
        },
      ],
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        {
          name: "Separator",
          props: {
            topColor: "blue-light",
            bottomColor: "gray",
          },
        },
        OfferGrid,
        footer,
      ],
    },
  ],
};

export default data;
