const data = {
  name: "StayUpdated",
  props: {
    title: "Stay updated with\n news on all our games",
    htmlText: `<p>Want to be the first to know about<br/>
                new releases? Get on the VIP list.</p>`,
    cta: {
      title: "Become a VIP",
      hrefTo: "/newsletter",
    },
    image: {
      src:
        "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/stay_updated/stay_updated.png",
      alt: "",
    },
  },
};
export default data;
