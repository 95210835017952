const data = {
  name: "OfficeTour",
  props: {
    title: {
      text: "Office Tour",
      size: "small",
      color: "purple",
      icon: {
        src:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/gallery-right-arrow.svg",
        alt: "",
      },
    },
    items: [
      {
        id: "1",
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/office_tour/office_tour_1.jpg",
          alt: "",
        },
        title: "Image 1",
      },
      {
        id: "2",
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/office_tour/office_tour_2.jpg",
          alt: "",
        },
        title: "Image 2",
      },
      {
        id: "3",
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/office_tour/office_tour_3.jpg",
          alt: "",
        },
        title: "Image 1",
      },
      {
        id: "4",
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/office_tour/office_tour_4.jpg",
          alt: "",
        },
        title: "Image 2",
      },
      {
        id: "5",
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/office_tour/office_tour_5.jpg",
          alt: "",
        },
        title: "Image 2",
      },
      {
        id: "6",
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/office_tour/office_tour_6.jpg",
          alt: "",
        },
        title: "Image 2",
      },
      {
        id: "7",
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/office_tour/office_tour_8.jpg",
          alt: "",
        },
        title: "Image 2",
      },
      {
        id: "8",
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/office_tour/office_tour_9.jpg",
          alt: "",
        },
        title: "Image 2",
      },
      {
        id: "9",
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/office_tour/office_tour_10.jpg",
          alt: "",
        },
        title: "Image 2",
      },
      {
        id: "10",
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/office_tour/office_tour_11.jpg",
          alt: "",
        },
        title: "Image 2",
      },
    ],
  },
};
export default data;
