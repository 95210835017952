import Menu from "./sections/Menu";
import Footer from "./sections/Footer";
import JumpAboard from "./sections/JumpAboard";
import Relocate from "./sections/Relocate";

const data = {
  meta: {
    title: "Socialpoint Benefits",
    description:
      "It's not all work, work, work.Take a look at all the freebies for Socialpointers.",
    keywords:
      "socialpoint, social point, socialpoint benefits, socialpoint freebies, socialpoint private medical insurance, socialpoint wellbeing, socialpoint gym, socialpoint free lunch, socialpoint free meals, socialpoint work benefits, socialpoint language courses"
  },
  menu: Menu(4),
  breadcrumb: {
    color: "purple",
    arrow:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrow-right.svg",
    items: [
      { title: "home", hrefTo: "/" },
      { title: "join us", hrefTo: "/join-us" },
      { title: "benefits", hrefTo: "/relocate" }
    ]
  },
  components: [
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray"
      },
      components: [
        {
          name: "BenefitsTwoImagesTop",
          props: {
            title: "Enjoy our benefits",
            backgroundColor: "gray",
            htmlText: `<p>It’s not all work, work, work. Take a look at all the freebies for Socialpointers.</p>`,
            image1: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/benefits/physio.jpg",
              alt: ""
            },
            image2: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/benefits/benefits_2.png",
              alt: ""
            },
            items: [
              {
                icon: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/benefit_health.svg",
                  alt: ""
                },
                title: "Private medical insurance and physiotherapy",
                htmlText:
                  "<p>We care about your wellbeing. That’s why we offer private medical insurance for you – and your family.</p><p>And every office worker’s dream; we even have a physiotherapist at the office. Say goodbye to aches and pains!</p>"
              },
              {
                icon: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/benefit_meal.svg",
                  alt: ""
                },
                title: "Free meals and on-site gym",
                htmlText:
                  "<p>We know that a healthy body leads to a healthy mind, and keeps those ideas ﬂowing. We oﬀer nutritional lunches, with veggie options, as well as fruit, daily snacks and other yummy surprises.</p><p>Another way to keep in shape is by using our amazing Socialpointers-only gym, open all day long. Work out with the guidance of personal trainers or attend a multitude of diﬀerent classes: Boxing, Yoga, Metabolic Training, Pilates...</p><p>Try them all out!</p>",
                alt: ""
              },
              {
                icon: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/benefit_gaming.svg",
                  alt: ""
                },
                title: "Hairdresser, social events and gaming area",
                htmlText:
                  "<p>Yes, the rumors are true; we have a hair salon at the oﬃce! Our hairdressers will cut and color your hair or trim your beard, so you look exactly the way you want to. Especially when there’s one of our legendary events coming up.</p><p>Because playtime is just as important. You’ll ﬁnd out why when you attend your ﬁrst summer pool party.</p><p>In the meantime, enjoy our gaming area every day at the oﬃce. There are video, arcade and board games, instruments, and much more.</p>",
                alt: ""
              }
            ]
          }
        },
        {
          name: "Separator",
          props: {
            topColor: "gray",
            bottomColor: "blue-light"
          }
        }
      ]
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "blue-light"
      },
      components: [
        {
          name: "BenefitsTwoImages",
          props: {
            backgroundColor: "blue-light",
            image1: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/benefits/sun-bath.jpg",
              alt: ""
            },
            image2: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/benefits/benefits_4.png",
              alt: ""
            },
            items: [
              {
                icon: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/benefit_language.svg",
                  alt: ""
                },
                title: "Training and language courses",
                htmlText:
                  "<p>We are lifelong learners, always eager to ﬁnd new answers and grow. That’s why we oﬀer a wide variety of options to level-up our skills; corporate training sessions, internal masterclasses, online platforms…</p><p>In addition, Socialpointers enjoy a generous yearly budget to spend on conferences, master’s degrees, etc.</p><p>That’s not all. You can also take language lessons at the oﬃce. English or Spanish? Which language would you like to learn or improve upon?</p>"
              },
              {
                icon: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/benefit_vacation.svg",
                  alt: ""
                },
                title: "Additional vacation days",
                htmlText:
                  "<p>We have 25 vacation days a year (more than the standard) in addition to the local 14 bank holidays.</p><p>Ready to sunbathe?</p>"
              },

              {
                icon: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/benefit_gaming.svg",
                  alt: ""
                },
                title: "The list of advantages goes on and on.",
                htmlText: "<p>Come aboard and find out for yourself.</p>"
              }
            ]
          }
        },
        {
          name: "FullWidthImage",
          props: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/benefits/full-width.jpg",
            alt: "Team"
          }
        },
        {
          name: "Wrapper",
          props: {
            backgroundColor: "purple-light"
          },
          components: [Relocate]
        },
        {
          name: "Separator",
          props: {
            topColor: "purple-light",
            bottomColor: "gray"
          }
        }
      ]
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray"
      },
      components: [JumpAboard, Footer]
    }
  ]
};

export default data;
