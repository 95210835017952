import menu from "./sections/Menu";
import Footer from "./sections/Footer";

const data = {
  meta: {
    title: "Socialpoint Job Alerts",
    description:
      "Sign up to receive job alerts, news and promotional messages from Socialpoint S.L and affiliates",
    keywords:
      "socialpoint, social point, socialpoint job alerts, socialpoint sign up, socialpoint promotional messages, Take Two Interactive, T2, Take 2 Interactive",
  },
  menu: menu(4),
  breadcrumb: {
    color: "purple",
    arrow:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrow-right.svg",
    items: [
      { title: "home", hrefTo: "/" },
      { title: "join alerts", hrefTo: "/job-alerts" },
    ],
  },
  components: [
    {
      name: "Wrapper",
      props: {
        backgroundColor: "yellow",
      },
      components: [
        {
          name: "JobAlerts",
          props: {
            title: "Sign up for job alerts",
            image: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/00.00_home/Home_1.png",
              alt: "",
            },
            form: {
              fields: [
                {
                  label: "Email",
                  type: "email",
                },
              ],
              color: "purple",
              htmlText: `
                <p>
                I would like to receive news and promotional messages from Socialpoint S.L and affiliates. 
                I can unsuscribe at any time by emailling 
                <a mailto="unsuscribe@socialpoint.es">
                  <u>unsuscribe@socialpoint.es</u>
                </a> 
                with “Unsuscribe” in the subject line.
                </p>
                <p>
                I confirm that I have read and understand Socialpoint S.L’s <u>Privacy Policy</u>
                & <u>Terms of Service.</u>
                </p>
                `,
              cta: {
                color: "purple",
                resultSuccess: "Your info was sent",
                resultError: "There was a problem with your info",
                loading: "Sending...",
                title: "Submit",
              },
              endpoint: `${process.env.GATSBY_STRAPI_API_URL}/greenhouse/apply`,
            },
          },
        },
      ],
    },
    Footer,
  ],
};

export default data;
