const data = {
  name: "LifeAtSocialpoint",
  props: {
    top: {
      title: "Life at Socialpoint",
      htmlText:
        "<p>One thing’s for sure – talent at Socialpoint is everywhere. From the game teams to the corporate and horizontal departments, each and every Socialpointer is undoubtedly a game maker.</p>",
      cta: {
        title: "Read more",
        icon: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
          alt: ""
        },
        hrefTo: "/about"
      },
      image1: {
        src:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/home/life-at-socialpoint/life_at_socialpoint_1.jpg",
        alt: ""
      },
      image2: {
        src:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/home/life-at-socialpoint/life_at_socialpoint_2.jpg",
        alt: ""
      }
    },
    middle: {
      title:
        "We build unforgettable games that delight millions of fans all around the globe.",
      htmlText:
        "<p>And we do so by thoroughly enjoying every day at work. Having fun is the best way we know to create fun.</p>",
      cta: {
        title: "Join us",
        icon: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
          alt: ""
        },
        hrefTo: "/join-us"
      },
      image: {
        src:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/home/life-at-socialpoint/life_at_socialpoint_3.jpg",
        alt: ""
      }
    },
    bottom: {
      titlePart1: "We're part of",
      titlePart2: "a big family",
      htmlText:
        "<p>As a startup, Socialpoint has been tight-knit from the very beginning.</p><p>Our roots still remain; only now the family has grown! And not just because there are over 330 of us…</p><p>In February 2017, we were acquired by Take Two Interactive, one of the top gaming companies in the world. Today, together with Rockstar, 2K and Private Division, we belong to the T2 family too, and we couldn’t be prouder!</p>",
      logo: {
        src:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/taketwointeractive.png",
        alt: ""
      },
      image: {
        src:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/life_at_socialpoint/life_at_socialpoint_4.png",
        alt: ""
      }
    }
  }
};
export default data;
