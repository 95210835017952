const data = {
  name: "Relocate",
  props: {
    image1: {
      src:
        "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/relocate/relocate_1.png",
      alt: ""
    },
    image2: {
      src:
        "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/join-us/relocate/image_2.jpg",
      alt: ""
    },
    image3: {
      src:
        "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/join-us/relocate/image_3.jpg",
      alt: ""
    },
    title: `We'll help you relocate`,
    htmlText: `
          <p>We want you to feel at home as soon as possible.
          If you’re coming from abroad, you’ll be settled  in no time.</p>`,
    cta: {
      hrefTo: "/join-us/relocate",
      title: "Know more"
    }
  }
};

export default data;
