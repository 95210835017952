import meta from "./sections/Meta";
import Footer from "./sections/Footer";
import Menu from "./sections/Menu";
import QuoteWithImage from "./sections/QuoteWithImage";
import JobOpportunities from "./sections/JobOpportunities";

const data = {
  meta,
  menu: Menu(4),
  breadcrumb: {
    color: "purple",
    arrow:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrow-right.svg",
    items: [
      { title: "home", hrefTo: "/" },
      { title: "join us", hrefTo: "/join-us" },
    ],
  },
  components: [
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        {
          name: "CareerOpenings",
          props: {
            title: "---",
            htmlText: `---`,
            image: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/gamedesign.png",
              alt: "",
            },
          },
        },
        // OfferGrid,
        {
          name: "OfferGrid",
          props: {
            title: "Careers Category",
            noOffers: {
              title: "",
              text:
                "We don’t have any vacancy in this department in this moment.",
              cta: {
                title: "View all the openings",
                hrefTo: "/join-us/job-openings",
              },
            },
            offerCard: {
              image: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/offer_hole.png",
                alt: "",
              },
              cta: {
                icon: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
                  alt: "",
                },
                title: "Read offer",
              },
            },
            teams: [{ label: "Analytics", value: 1 }],
            offers: [
              {
                title: "Lead Game Designer",
                teams: [6],
                hrefTo: "",
              },
            ],
          },
        },
        QuoteWithImage,
        {
          name: "Separator",
          props: {
            topColor: "gray",
            bottomColor: "red",
          },
        },
        JobOpportunities,
        {
          name: "Separator",
          props: {
            topColor: "red",
            bottomColor: "red",
          },
        },
      ],
    },
    Footer,
  ],
};

export default data;
