import menu from "./sections/Menu";
import Footer from "./sections/Footer";
import News from "./sections/News";
import TheFounders from "./sections/TheFounders";
import OurStory from "./sections/OurStory";
import LifeAtSocialpointSimple from "./sections/LifeAtSocialpointSimple";

const data = {
  meta: {
    title: "Socialpoint About Us",
    description: "Have fun together creating fun for everyone.",
    keywords:
      "socialpoint, social point, socialPointers, socialpoint workers, socialpoint culture, socialpoint values, socialpoint passion, socialpoint interaction, Take Two Interactive, T2, Take 2 Interactive"
  },
  menu: menu(3),
  breadcrumb: {
    color: "purple",
    arrow:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrow-right.svg",
    items: [
      { title: "home", hrefTo: "/" },
      { title: "about", hrefTo: "/about" }
    ]
  },
  components: [
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray"
      },
      components: [
        {
          name: "About",
          props: {
            top: {
              title: "Have fun together creating fun for everyone",
              htmlText: `
                <p>We were born a startup and, despite having grown, our mindset remains just as ambitious. We’re bold and we dream big; that’s how we make great things happen. </p>
                <br>
                <h4 class="bold">Our vision</h4>
                <p>Socialpointers are always keen to help and eager for a challenge. We all come from diﬀerent origins and professional backgrounds – speaking over 30 languages between us! When we join forces, we’re able to excite and entertain our fans, wherever they are.</p>
                <p><span class="bold">That’s our vision; to have fun together, creating fun for everyone.</span> How? When we have fun, we can create it. And by working together, we’ll know how to reach everyone.</p>
                `,
              image: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/about/about_1.jpg",
                alt: ""
              },
              cta: {
                title: "View all openings",
                hrefTo: "/join-us/careers"
              }
            },
            middle: {
              image: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/about/about_2.png",
                alt: ""
              },
              htmlText: `
                <h4 class="bold">Our culture</h4>
                <p>At Socialpoint, we are passionate gamers. Curious and humble, we enjoy experimenting and learning from everything around us; especially from our users. Their feedback shows us the way to our next big hit. Firstly, we pour our heart and soul into the games we create. Then, we listen to the community and make iterations until we reach the perfect product  – one that exceeds their expectations.</p>
                <p>Working in the mobile gaming industry, we embrace change and move fast. There’s no other way of being at the top. When it comes to cooperating, we’ve got teamplayer mode switched on!
                We mostly work in small, cross-functional teams. This way, we’re agile and able to listen to what everyone has to say. Proactivity, accountability and ownership are all key to achieving our goals.</p>
                `
            },
            bottom: {
              video: {
                src: "https://vimeo.com/980441238",
                poster:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/about/our-value/our-value-thumbnail.jpg",
                player: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/player-play.svg",
                  alt: ""
                },
                fullWidth: true
              },
              // image: {
              //   src:
              //     "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/about/about_3.jpg",
              //   alt: ""
              // },
              htmlText: `
                <h4>Our values</h4>
                <br>
                <p class="bold uppercase">Passion</p>
                <p>We couldn’t be luckier. We were born to create great games, and we do it for a living! Loving what we do permeates every project, from the word go.</p>
                <br>
                <p class="bold uppercase">Innovation</p>
                <p>We think out of the box and do things diﬀerently in order
                to come up with innovative  gameplays that amaze the world. Creativity is our weapon. Not even the sky's the limit.</p>
                <br>
                <p class="bold uppercase">Transparency</p>
                <p>We share our knowledge, opinions, success and mistakes. We believe in open and honest communication, to enforce trust and teamwork.</p>
                `
            },
            video: {
              src: "https://vimeo.com/375628531/fcec91fd1c",
              poster:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/video-poster.jpg",
              player: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/player-play.svg",
                alt: ""
              }
            }
          }
        },
        {
          name: "Separator",
          props: {
            topColor: "gray",
            bottomColor: "blue-light"
          }
        },
        {
          name: "Wrapper",
          props: {
            backgroundColor: "blue-light"
          },
          components: [
            {
              name: "CounterWithImage",
              props: {
                highlight:
                  "Socialpoint is a world-renowned social game developer and publisher, specialized in mobile gaming.",
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/about/about_4.png",
                  alt: ""
                },
                items: [
                  {
                    number: "2M+",
                    text: "unique daily users."
                  },
                  {
                    number: "350",
                    text: "Socialpointers working together to amaze you."
                  },
                  {
                    number: "15",
                    text: "years of history.\n And counting."
                  },
                  {
                    number: "870M+",
                    text: "downloads, or 11% of the world's population."
                  },
                  {
                    number: "3,600+",
                    text: "characters designed.\n That's a lot to play with."
                  }
                ]
              }
            }
          ]
        },
        {
          name: "Separator",
          props: {
            topColor: "blue-light",
            bottomColor: "purple-light" // prev value - purple
          }
        },
        /*
        ---- Hiding "The founders" and "Our story" sections temporarily ---- 
        {
          name: "Wrapper",
          props: {
            backgroundColor: "purple"
          },
          components: [TheFounders]
        },
        {
          name: "Separator",
          props: {
            topColor: "purple",
            bottomColor: "purple-light"
          }
        },
        {
          name: "Wrapper",
          props: {
            backgroundColor: "purple-light"
          },
          components: [OurStory]
        },
        {
          name: "Separator",
          props: {
            topColor: "purple-light",
            bottomColor: "blue-light"
          }
        },
        */
        {
          name: "Wrapper",
          props: {
            backgroundColor: "purple-light" // prev val - blue-light
          },
          components: [
            LifeAtSocialpointSimple,
            {
              name: "Separator",
              props: {
                topColor: "purple-light", // prev val - blue-light
                bottomColor: "red",
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/about/about_5.png",
                  alt: "",
                  position: "left",
                  align: "center",
                  top: "-top-16 md:-top-16"
                }
              }
            }
          ]
        },
        {
          name: "Wrapper",
          props: {
            backgroundColor: "red"
          },
          components: [News]
        },
        Footer
      ]
    }
  ]
};

export default data;
