const data = {
  name: "News",
  props: {
    title: "News and\nsocial media",
    socialTitle: "check out the latest",
    items: [
      {
        id: 1,
        icon: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/linkedin.svg",
          alt: "",
        },
        htmlText: `
        `,
        cta: {
          title: "Read more on our Linkedin",
          url: "https://www.linkedin.com/company/social-point",
          icon: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
            alt: "",
          },
        },
      },
      {
        id: 2,
        icon: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/facebook.svg",
          alt: "",
        },
        htmlText: "",
        cta: {
          title: "Read more on our Facebook",
          url: "https://www.facebook.com/socialpoint/",
          icon: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
            alt: "",
          },
        },
      },
      {
        id: 3,
        icon: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/twitter.svg",
          alt: "",
        },
        htmlText: "",
        cta: {
          title: "Read more on our Twitter",
          url: "https://twitter.com/socialpoint",
          icon: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
            alt: "",
          },
        },
      },
    ],
    social: [
      {
        name: "Facebook",
        icon:
          "https://sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/facebook.svg",
        url: "https://www.facebook.com/socialpoint/",
      },
      {
        name: "Twitter",
        icon:
          "https://sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/twitter.svg",
        url: "https://twitter.com/socialpoint",
      },
      {
        name: "Instagram",
        icon:
          "https://sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/instagram.svg",
        url: "https://www.instagram.com/socialpoint",
      },
      {
        name: "LinkedIn",
        icon:
          "https://sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/linkedin.svg",
        url: "https://www.linkedin.com/company/social-point",
      },
      {
        name: "Youtube",
        icon:
          "https://sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/youtube.svg",
        url: "https://www.youtube.com/user/SocialPointChannel",
      },
    ],
  },
};
export default data;
