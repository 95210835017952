import menu from "./sections/Menu";
import meta from "./sections/Meta";
import Footer from "./sections/Footer";

const data = {
  meta,
  menu: menu(),
  breadcrumb: {
    color: "purple",
    arrow:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrow-right.svg",
    items: [
      { title: "home", hrefTo: "/" },
      { title: "ad partners", hrefTo: "/ad-partners" },
    ],
  },
  components: [
    {
      name: "Wrapper",
      props: {
        backgroundColor: "purple",
      },
      components: [
        {
          name: "AdPartners",
          props: {
            selectedLang: "en",
            langs: [
              {
                code: "en",
                language: "ENGLISH",
              },
              {
                code: "fr",
                language: "FRANÇAIS",
              },
              {
                code: "nl",
                language: "NEDERLANDS",
              },
              {
                code: "de",
                language: "DEUTSCH",
              },
              {
                code: "es",
                language: "ESPAÑOL (EU)",
              },
              {
                code: "mx",
                language: "ESPAÑOL (MX)",
              },
              {
                code: "gr",
                language: "Ελληνικά",
              },
              {
                code: "pl",
                language: "Polski",
              },
              {
                code: "cz",
                language: "Čeština",
              },
              {
                code: "ru",
                language: "Русский",
              },
              {
                code: "pt",
                language: "Português",
              },
              {
                code: "it",
                language: "Italiano",
              },
              {
                code: "tu",
                language: "Türkçe",
              },
              {
                code: "ar",
                language: "عربي",
              },
              {
                code: "tc",
                language: "繁體中文",
              },
              {
                code: "kr",
                language: "한국어",
              },
              {
                code: "jp",
                language: "日本語",
              },
              {
                code: "sc",
                language: "简体中文",
              },
            ],
            entries: [
              // AdAction,https://www.adaction.com/privacy-policy,"https://www.adaction.com/privacy-policy,(Section 7)"
              {
                partner: "AdAction",
                privacyPolicy: "https://www.adaction.com/privacy-policy",
                userChoice: [
                  {
                    url: "https://www.adaction.com/privacy-policy",
                    comments: "(Section 7)",
                  },
                ],
              },
              // AdColony,https://www.adcolony.com/consumer-privacy/,"https://www.adcolony.com/privacy-policy/,(Choices Regarding Ad Targeting And Data Use)"
              {
                partner: "AdColony",
                privacyPolicy: "https://www.adcolony.com/consumer-privacy/",
                userChoice: [
                  {
                    url: "https://www.adcolony.com/privacy-policy/",
                    comments: "(Choices Regarding Ad Targeting And Data Use)",
                  },
                ],
              },
              // Adform,https://site.adform.com/privacy-center/platform-privacy/product-and-services-privacy-policy/,https://site.adform.com/privacy-center/platform-privacy/opt-out/
              {
                partner: "Adform",
                privacyPolicy:
                  "https://site.adform.com/privacy-center/platform-privacy/product-and-services-privacy-policy/",
                userChoice: [
                  {
                    url:
                      "https://site.adform.com/privacy-center/platform-privacy/opt-out/",
                    comments: "",
                  },
                ],
              },
              // Adikteev,https://www.adikteev.com/privacy,"https://www.adikteev.com/privacy-policy-app-users,(Section 7)"
              {
                partner: "Adikteev",
                privacyPolicy: "https://www.adikteev.com/privacy",
                userChoice: [
                  {
                    url: "https://www.adikteev.com/privacy-policy-app-users",
                    comments: "(Section 7)",
                  },
                ],
              },
              // Adjoe,https://adjoe.io/privacy/,"https://adjoe.io/privacy/,(Use of Cookies)"
              {
                partner: "Adjoe",
                privacyPolicy: "https://adjoe.io/privacy/",
                userChoice: [
                  {
                    url: "https://adjoe.io/privacy/",
                    comments: "(Use of Cookies)",
                  },
                ],
              },
              // Admob,https://policies.google.com/privacy,https://policies.google.com/technologies/product-privacy/
              {
                partner: "Admob",
                privacyPolicy: "https://policies.google.com/privacy",
                userChoice: [
                  {
                    url:
                      "https://policies.google.com/technologies/product-privacy/",
                    comments: "",
                  },
                ],
              },
              // Altrooz,https://altrooz.com/privacy-policy.html,"https://altrooz.com/privacy-policy.html,(Interest-Based Advertising and Opt Out)"
              {
                partner: "Altrooz",
                privacyPolicy: "https://altrooz.com/privacy-policy.html",
                userChoice: [
                  {
                    url: "https://altrooz.com/privacy-policy.html",
                    comments: "(Interest-Based Advertising and Opt Out)",
                  },
                ],
              },
              // Amazon,https://www.amazon.com/gp/help/customer,https://www.amazon.com/adprefs
              {
                partner: "Amazon",
                privacyPolicy: "https://www.amazon.com/gp/help/customer",
                userChoice: [
                  {
                    url: "https://www.amazon.com/adprefs",
                    comments: "",
                  },
                ],
              },
              // Anzu,https://www.anzu.io/privacy-policy/,https://www.anzu.io/privacy-policy/ (section 7)
              {
                partner: "Anzu",
                privacyPolicy: "https://www.anzu.io/privacy-policy/",
                userChoice: [
                  {
                    url: "https://www.anzu.io/privacy-policy/",
                    comments: "(section 7)",
                  },
                ],
              },
              // Apple Search Ads,https://searchads.apple.com/privacy,https://support.apple.com/en-us/HT202074
              {
                partner: "Apple Search Ads",
                privacyPolicy: "https://searchads.apple.com/privacy",
                userChoice: [
                  {
                    url: "https://support.apple.com/en-us/HT202074",
                    comments: "",
                  },
                ],
              },
              // Applovin,https://www.applovin.com/privacy/#advertising-services,https://dash.applovin.com/p/how-applovin-shows-you-ads
              {
                partner: "Applovin",
                privacyPolicy:
                  "https://www.applovin.com/privacy/#advertising-services",
                userChoice: [
                  {
                    url:
                      "https://dash.applovin.com/p/how-applovin-shows-you-ads",
                    comments: "",
                  },
                ],
              },
              // Bidstack,,
              // {
              //   partner: "Bidstack",
              //   privacyPolicy: "",
              //   userChoice: [{
              //     url: "",
              //     comments:"",
              //   },],
              // },
              // Bing,https://privacy.microsoft.com/en-us/privacystatement,https://account.microsoft.com/privacy/ad-settings
              {
                partner: "Bing",
                privacyPolicy:
                  "https://privacy.microsoft.com/en-us/privacystatement",
                userChoice: [
                  {
                    url: "https://account.microsoft.com/privacy/ad-settings",
                    comments: "",
                  },
                ],
              },
              // Blind Ferret,https://blindferret.com/privacy-policy/,"https://blindferret.com/privacy-policy/,(Detailed information on the processing of Personal Data)"
              {
                partner: "Blind Ferret",
                privacyPolicy: "https://blindferret.com/privacy-policy/",
                userChoice: [
                  {
                    url: "https://blindferret.com/privacy-policy/",
                    comments:
                      "(Detailed information on the processing of Personal Data)",
                  },
                ],
              },
              // Bluestacks,https://www.bluestacks.com/terms-and-privacy.html,"https://www.bluestacks.com/terms-and-privacy.html,(What choices do I have?)"
              {
                partner: "Bluestacks",
                privacyPolicy:
                  "https://www.bluestacks.com/terms-and-privacy.html",
                userChoice: [
                  {
                    url: "https://www.bluestacks.com/terms-and-privacy.html",
                    comments: "(What choices do I have?)",
                  },
                ],
              },
              // Chartboost,https://answers.chartboost.com/en-us/articles/200780269,"https://answers.chartboost.com/en-us/articles/200780269,(Section 8)"
              {
                partner: "Chartboost",
                privacyPolicy:
                  "https://answers.chartboost.com/en-us/articles/200780269",
                userChoice: [
                  {
                    url:
                      "https://answers.chartboost.com/en-us/articles/200780269",
                    comments: "(Section 8)",
                  },
                ],
              },
              // Criteo,https://www.criteo.com/privacy/,https://www.criteo.com/privacy/disable-criteo-services-on-mobile-applications/
              {
                partner: "Criteo",
                privacyPolicy: "https://www.criteo.com/privacy/",
                userChoice: [
                  {
                    url:
                      "https://www.criteo.com/privacy/disable-criteo-services-on-mobile-applications/",
                    comments: "",
                  },
                ],
              },
              // CrossInstall,https://www.mopub.com/en/legal/privacy,"https://www.mopub.com/en/legal/privacy,(Section 1.4)"
              {
                partner: "CrossInstall",
                privacyPolicy: "https://www.mopub.com/en/legal/privacy",
                userChoice: [
                  {
                    url: "https://www.mopub.com/en/legal/privacy",
                    comments: "(Section 1.4)",
                  },
                ],
              },
              // CyberZ,https://cyber-z.co.jp/en/privacy-usa,"https://cyber-z.co.jp/en/privacy-usa,(Opt-Out Provision)"
              {
                partner: "CyberZ",
                privacyPolicy: "https://cyber-z.co.jp/en/privacy-usa",
                userChoice: [
                  {
                    url: "https://cyber-z.co.jp/en/privacy-usa",
                    comments: "(Opt-Out Provision)",
                  },
                ],
              },
              // Dataseat,"https://www.dataseat.com/privacy-policy#:~:text=Dataseat%20retains%20data%20for%20up,or%20the%20safety%20of%20others.","https://www.dataseat.com/privacy-policy#:~:text=Dataseat%20retains%20data%20for%20up,or%20the%20safety%20of%20others."
              {
                partner: "Dataseat",
                privacyPolicy:
                  "https://www.dataseat.com/privacy-policy#:~:text=Dataseat%20retains%20data%20for%20up,or%20the%20safety%20of%20others.",
                userChoice: [
                  {
                    url:
                      "https://www.dataseat.com/privacy-policy#:~:text=Dataseat%20retains%20data%20for%20up,or%20the%20safety%20of%20others.",
                    comments: "",
                  },
                ],
              },
              // Digital Turbine,https://www.digitalturbine.com/privacy-policy/,"https://www.digitalturbine.com/privacy-policy/,(Interest-based advertising)"
              {
                partner: "Digital Turbine",
                privacyPolicy: "https://www.digitalturbine.com/privacy-policy/",
                userChoice: [
                  {
                    url: "https://www.digitalturbine.com/privacy-policy/",
                    comments: "(Interest-based advertising)",
                  },
                ],
              },
              // Facebook (Meta),https://www.facebook.com/privacy/explanation,https://www.facebook.com/adpreferences/ad_settings
              {
                partner: "Facebook (Meta)",
                privacyPolicy: "https://www.facebook.com/privacy/explanation",
                userChoice: [
                  {
                    url: "https://www.facebook.com/adpreferences/ad_settings",
                    comments: "",
                  },
                ],
              },
              // Feedmob,https://feedmob.com/privacy-policy/,"https://feedmob.com/privacy-policy/,(Section 8)"
              {
                partner: "Feedmob",
                privacyPolicy: "https://feedmob.com/privacy-policy/",
                userChoice: [
                  {
                    url: "https://feedmob.com/privacy-policy/",
                    comments: "(Section 8)",
                  },
                ],
              },
              // Fluent,https://www.fluentco.com/privacy-policy/,https://www.fluentco.com/privacy-policy/
              {
                partner: "Fluent",
                privacyPolicy: "https://www.fluentco.com/privacy-policy/",
                userChoice: [
                  {
                    url: "https://www.fluentco.com/privacy-policy/",
                    comments: "",
                  },
                ],
              },
              // Fyber,https://www.fyber.com/services-privacy-statement/,"https://www.fyber.com/services-privacy-statement/,(Section 6)"
              {
                partner: "Fyber",
                privacyPolicy:
                  "https://www.fyber.com/services-privacy-statement/",
                userChoice: [
                  {
                    url: "https://www.fyber.com/services-privacy-statement/",
                    comments: "(Section 6)",
                  },
                ],
              },
              // Google,https://policies.google.com/technologies/ads?hl=en-US,https://adssettings.google.com/authenticated?hl=en
              {
                partner: "Google",
                privacyPolicy:
                  "https://policies.google.com/technologies/ads?hl=en-US",
                userChoice: [
                  {
                    url: "https://adssettings.google.com/authenticated?hl=en",
                    comments: "",
                  },
                ],
              },
              // hangmyads,https://affiliates.hangmytracking.com/index.php?pag=terms,https://affiliates.hangmytracking.com/index.php?pag=terms
              {
                partner: "hangmyads",
                privacyPolicy:
                  "https://affiliates.hangmytracking.com/index.php?pag=terms",
                userChoice: [
                  {
                    url:
                      "https://affiliates.hangmytracking.com/index.php?pag=terms",
                    comments: "",
                  },
                ],
              },
              // HyprMX,https://www.hyprmx.com/pp,hhttps://www.hyprmx.com/pp
              {
                partner: "HyprMX",
                privacyPolicy: "https://www.hyprmx.com/pp",
                userChoice: [
                  {
                    url: "https://www.hyprmx.com/pp",
                    comments: "",
                  },
                ],
              },
              // Instagram,https://help.instagram.com/1896641480634370?ref=ig,https://www.instagram.com/accounts/login/?next=/accounts/privacy_and_security/
              {
                partner: "Instagram",
                privacyPolicy:
                  "https://help.instagram.com/1896641480634370?ref=ig",
                userChoice: [
                  {
                    url:
                      "https://www.instagram.com/accounts/login/?next=/accounts/privacy_and_security/",
                    comments: "",
                  },
                ],
              },
              // IronSource,https://www.is.com/privacy-policy/,https://developers.is.com/ironsource-mobile/air/ironsource-mobile-privacy-policy/#step-6
              {
                partner: "IronSource",
                privacyPolicy: "https://www.is.com/privacy-policy/",
                userChoice: [
                  {
                    url:
                      "https://developers.is.com/ironsource-mobile/air/ironsource-mobile-privacy-policy/#step-6",
                    comments: "",
                  },
                ],
              },
              // InMobi,https://www.inmobi.com/privacy-policy,https://www.inmobi.com/page/opt-out
              {
                partner: "InMobi",
                privacyPolicy: "https://www.inmobi.com/privacy-policy",
                userChoice: [
                  {
                    url: "https://www.inmobi.com/page/opt-out",
                    comments: "",
                  },
                ],
              },
              // JumpRamp,https://jumprampgames.com/privacy-policy/,"https://jumprampgames.com/privacy-policy/,(Section 2)"
              {
                partner: "JumpRamp",
                privacyPolicy: "https://jumprampgames.com/privacy-policy/",
                userChoice: [
                  {
                    url: "https://jumprampgames.com/privacy-policy/",
                    comments: "(Section 2)",
                  },
                ],
              },
              // LifeStreet Media,https://lifestreet.com/privacy/,https://lifestreet.com/optout
              {
                partner: "LifeStreet Media",
                privacyPolicy: "https://lifestreet.com/privacy/",
                userChoice: [
                  {
                    url: "https://lifestreet.com/optout",
                    comments: "",
                  },
                ],
              },
              // Liftoff,https://liftoff.io/privacy-policy/,https://liftoff.io/opt-out/
              {
                partner: "Liftoff",
                privacyPolicy: "https://liftoff.io/privacy-policy/",
                userChoice: [
                  {
                    url: "https://liftoff.io/opt-out/",
                    comments: "",
                  },
                ],
              },
              // Magnite ,https://www.magnite.com/legal/master-terms-conditions/,https://www.magnite.com/legal/advertising-technology-privacy-policy/#Your-Opt-Out-Choices
              {
                partner: "Magnite",
                privacyPolicy:
                  "https://www.magnite.com/legal/master-terms-conditions/",
                userChoice: [
                  {
                    url:
                      "https://www.magnite.com/legal/advertising-technology-privacy-policy/#Your-Opt-Out-Choices",
                    comments: "",
                  },
                ],
              },
              // Mistplay,https://www.mistplay.com/legal/privacy,https://www.mistplay.com/legal/privacy
              {
                partner: "Mistplay",
                privacyPolicy: "https://www.mistplay.com/legal/privacy",
                userChoice: [
                  {
                    url: "https://www.mistplay.com/legal/privacy",
                    comments: "",
                  },
                ],
              },
              // Moloco,https://www.molocoads.com/advertising-policy,"https://www.molocoads.com/advertising-policy,(Your Choices)"
              {
                partner: "Moloco",
                privacyPolicy: "https://www.molocoads.com/advertising-policy",
                userChoice: [
                  {
                    url: "https://www.molocoads.com/advertising-policy",
                    comments: "(Your Choices)",
                  },
                ],
              },
              // Mopub,https://www.mopub.com/en/legal/privacy,https://www.mopub.com/en/legal/privacy#section1
              {
                partner: "Mopub",
                privacyPolicy: "https://www.mopub.com/en/legal/privacy",
                userChoice: [
                  {
                    url: "https://www.mopub.com/en/legal/privacy#section1",
                    comments: "",
                  },
                ],
              },
              // Motive Interactive,https://motiveinteractive.com/privacy-policy/,"https://motiveinteractive.com/privacy-policy/,(How to Opt-Out)"
              {
                partner: "Motive Interactive",
                privacyPolicy: "https://motiveinteractive.com/privacy-policy/",
                userChoice: [
                  {
                    url: "https://motiveinteractive.com/privacy-policy/",
                    comments: "(How to Opt-Out)",
                  },
                ],
              },
              // Pangle ,https://www.pangleglobal.com/privacy,https://www.pangleglobal.com/privacy/enduser-en
              {
                partner: "Pangle",
                privacyPolicy: "https://www.pangleglobal.com/privacy",
                userChoice: [
                  {
                    url: "https://www.pangleglobal.com/privacy/enduser-en",
                    comments: "",
                  },
                ],
              },
              // Persona.ly,https://persona.ly/privacy_policy,"https://persona.ly/privacy_dsp https://persona.ly/privacy_sdk,(Your choices and controls)"
              {
                partner: "Persona.ly",
                privacyPolicy: "https://persona.ly/privacy_policy",
                userChoice: [
                  {
                    url: "https://persona.ly/privacy_dsp",
                    comments: "",
                  },
                  {
                    url: "https://persona.ly/privacy_sdk",
                    comments: "(Your choices and controls)",
                  },
                ],
              },
              // Reddit,https://www.redditinc.com/policies/privacy-policy,https://www.redditinc.com/policies/privacy-policy#text-content3
              {
                partner: "Reddit",
                privacyPolicy:
                  "https://www.redditinc.com/policies/privacy-policy",
                userChoice: [
                  {
                    url:
                      "https://www.redditinc.com/policies/privacy-policy#text-content3",
                    comments: "",
                  },
                ],
              },
              // Remerge,https://www.remerge.io/service-privacy-policy,https://www.remerge.io/opt-out-of-ads
              {
                partner: "Remerge",
                privacyPolicy: "https://www.remerge.io/service-privacy-policy",
                userChoice: [
                  {
                    url: "https://www.remerge.io/opt-out-of-ads",
                    comments: "",
                  },
                ],
              },
              // Salesforce,https://www.salesforce.com/eu/company/privacy/,"https://www.salesforce.com/eu/company/privacy/full_privacy/,(Section 10)"
              {
                partner: "Salesforce",
                privacyPolicy: "https://www.salesforce.com/eu/company/privacy/",
                userChoice: [
                  {
                    url:
                      "https://www.salesforce.com/eu/company/privacy/full_privacy/",
                    comments: "(Section 10)",
                  },
                ],
              },
              // Septeni,https://www.septeni.co.jp/en/privacy/,https://www.septeni.co.jp/en/privacy/cookie/
              {
                partner: "Septeni",
                privacyPolicy: "https://www.septeni.co.jp/en/privacy/",
                userChoice: [
                  {
                    url: "https://www.septeni.co.jp/en/privacy/cookie/",
                    comments: "",
                  },
                ],
              },
              // Sizmek,https://www.sizmek.com/privacy-policy/#privacy-practices-advertising,https://www.sizmek.com/privacy-policy/#questions
              {
                partner: "Sizmek",
                privacyPolicy:
                  "https://www.sizmek.com/privacy-policy/#privacy-practices-advertising",
                userChoice: [
                  {
                    url: "https://www.sizmek.com/privacy-policy/#questions",
                    comments: "",
                  },
                ],
              },
              // Smadex,https://smadex.com/end-user-privacy-policy,https://smadex.com/end-user-privacy-policy
              {
                partner: "Smadex",
                privacyPolicy: "https://smadex.com/end-user-privacy-policy",
                userChoice: [
                  {
                    url: "https://smadex.com/end-user-privacy-policy",
                    comments: "",
                  },
                ],
              },
              // Snapchat,https://snap.com/en-US/ad-policies#general-information,https://support.snapchat.com/en-US/a/advertising-preferences
              {
                partner: "Snapchat",
                privacyPolicy:
                  "https://snap.com/en-US/ad-policies#general-information",
                userChoice: [
                  {
                    url:
                      "https://support.snapchat.com/en-US/a/advertising-preferences",
                    comments: "",
                  },
                ],
              },
              // Tapjoy,https://www.tapjoy.com/legal/general/privacy-policy/,https://www.tapjoy.com/legal/general/data-privacy-requests/
              {
                partner: "Tapjoy",
                privacyPolicy:
                  "https://www.tapjoy.com/legal/general/privacy-policy/",
                userChoice: [
                  {
                    url:
                      "https://www.tapjoy.com/legal/general/data-privacy-requests/",
                    comments: "",
                  },
                ],
              },
              // Tapresearch,https://www.tapresearch.com/legal/privacy-policy-en,Last section https://www.tapresearch.com/legal/privacy-policy-en
              {
                partner: "Tapresearch",
                privacyPolicy:
                  "https://www.tapresearch.com/legal/privacy-policy-en",
                userChoice: [
                  {
                    url: "https://www.tapresearch.com/legal/privacy-policy-en",
                    comments: "(Last section)",
                  },
                ],
              },
              // Taptica,https://www.taptica.com/privacy-policy/,https://www.taptica.com/cookies-policy/
              {
                partner: "Taptica",
                privacyPolicy: "https://www.taptica.com/privacy-policy/",
                userChoice: [
                  {
                    url: "https://www.taptica.com/cookies-policy/",
                    comments: "",
                  },
                ],
              },
              // TikTok,https://www.tiktok.com/legal/privacy-policy-us?lang=en,"https://www.tiktok.com/legal/cookie-policy?lang=en,(Section 4)"
              {
                partner: "TikTok (bytedance)",
                privacyPolicy:
                  "https://www.tiktok.com/legal/privacy-policy-us?lang=en",
                userChoice: [
                  {
                    url: "https://www.tiktok.com/legal/cookie-policy?lang=en",
                    comments: "(Section 4)",
                  },
                ],
              },
              // TreSensa,https://www.tresensa.com/privacy,"https://www.tresensa.com/privacy,(How to Opt Out of Interest-based Advertising)"
              {
                partner: "TreSensa",
                privacyPolicy: "https://www.tresensa.com/privacy",
                userChoice: [
                  {
                    url: "https://www.tresensa.com/privacy",
                    comments: "(How to Opt Out of Interest-based Advertising)",
                  },
                ],
              },
              // Twitter,https://twitter.com/en/privacy,https://twitter.com/settings/privacy_and_safety
              {
                partner: "Twitter",
                privacyPolicy: "https://twitter.com/en/privacy",
                userChoice: [
                  {
                    url: "https://twitter.com/settings/privacy_and_safety",
                    comments: "",
                  },
                ],
              },
              // Unity Ads,https://unity3d.com/legal/privacy-policy,"https://unity3d.com/legal/privacy-policy,(Section 8)"
              {
                partner: "Unity Ads",
                privacyPolicy: "https://unity3d.com/legal/privacy-policy",
                userChoice: [
                  {
                    url: "https://unity3d.com/legal/privacy-policy",
                    comments: "(Section 8)",
                  },
                ],
              },
              // Vungle,https://vungle.com/privacy/,https://vungle.com/privacy/#section-2-4
              {
                partner: "Vungle",
                privacyPolicy: "https://vungle.com/privacy/",
                userChoice: [
                  {
                    url: "https://vungle.com/privacy/#section-2-4",
                    comments: "",
                  },
                ],
              },
              // Walmart,https://corporate.walmart.com/privacy-security/walmart-privacy-policy,"https://corporate.walmart.com/privacy-security/notices/#about-our-ads,(Section 1)"
              {
                partner: "Walmart",
                privacyPolicy:
                  "https://corporate.walmart.com/privacy-security/walmart-privacy-policy",
                userChoice: [
                  {
                    url:
                      "https://corporate.walmart.com/privacy-security/notices/#about-our-ads",
                    comments: "(Section 1)",
                  },
                ],
              },
              // Magnite ,Sofia asked,
              // {
              //   partner: "",
              //   privacyPolicy: "",
              //   userChoice: [{
              //     url: "",
              //     comments:"",
              //   },],
              // },
            ],
          },
        },
      ],
    },
    Footer,
  ],
};

export default data;
