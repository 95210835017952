const data = {
  name: "WhatWeDo",
  props: {
    title: `What 
    we do
    `,
    htmlText: `
        <p>Ever heard of Dragon City, Monster Legends, Two Dots, or Top Troops? That’s us! Our egg hatched in 2008, and we’ve been connecting the world through awesome gameplay ever since.</p>
        `,
    image: {
      src:
        "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/what_we_do/what_we_do.png",
      alt: "",
    },
    highlight:
      "Socialpoint is a world-renowned game developer and publisher, specializing in mobile gaming, and a member of Take-Two Interactive and Zynga.",
    counters: {
      items: [
        { text: "unique daily users.", number: "2M+" },
        {
          text: "Socialpointers working together to amaze you.",
          number: "350+",
        },
        { text: "years of history.\n And counting.", number: "15" },
        {
          text: "downloads, or 11% of the world's population.",
          number: "870M+",
        },
        {
          text: "characters designed.\n That's a lot to play with.",
          number: "3,600+",
        },
      ],
    },
  },
};
export default data;
