import menu from "./sections/Menu";
import Footer from "./sections/Footer";
import JumpAboard from "./sections/JumpAboard";
import LifeAtSocialpoint from "./sections/LifeAtSocialpoint";

const data = {
  meta: {
    title: "Socialpoint Team",
    description:
      "The key to our success is our invaluable team, formed by 330 skilled, creative, and passionate Socialpointers, crazy about games and committed to pushing the bar. You can take their word for it.",
    keywords:
      "socialpoint, social point, socialpoint team, socialpoint workers, socialpoint employees, socialpoint highly skilled workers, SocialPointers, Take Two Interactive, T2, Take 2 Interactive",
  },
  menu: menu(4),
  breadcrumb: {
    color: "purple",
    arrow:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrow-right.svg",
    items: [
      { title: "home", hrefTo: "/" },
      { title: "join us", hrefTo: "/join-us" },
      { title: "team", hrefTo: "/join-us/team" },
    ],
  },
  components: [
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        {
          name: "MeetTheTeamDetail",
          props: {
            title: "Meet the team",
            subtitle: "Socialpoint on the inside",
            htmlText:
              '<p>The key to our success is our invaluable team, formed by <span class="bold">330 skilled, creative, and passionate Socialpointers</span>, crazy about games and committed to pushing the bar. You can take their word for it.',
            cta: {
              title: "View all openings",
              hrefTo: "/join-us/careers",
            },
            image: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/meet_the_team/meet_the_team_1.png",
              alt: "",
            },
            items: [
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/team/gillian.jpg",
                  alt: "",
                },
                icon: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/quotes.svg",
                  alt: "",
                },
                htmlText:
                  "<p>I feel like I'm surrounded by people who love what they do and have a lot of fun every day at work. Everyone is really friendly and made me feel at home from day one. At first, I was a little worried about moving abroad, but Socialpoint went out of their way to offer support and make it as easy as possible for me: I don't regret it for a second! There's so much to do in Barcelona that everyone will be able to find something they enjoy, and the weather makes living here a treat. I'm never going home!</p>",
                name: "Gillian Patterson",
                position: "Mobile Game Developer",
                city: "Troon",
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/team/pasqual.jpg",
                  alt: "",
                },
                icon: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/quotes.svg",
                  alt: "",
                },
                htmlText:
                  "<p>This is the best company I’ve ever worked for, starting with the product itself. Then there’s the environment, the city, the highly skilled people... The constant challenges that the industry faces makes it the perfect place to grow . In fact, my job is very diverse. I’m given the opportunity to be involved in many topics and every day there are new challenges to face. Also launching games to the market and listening to players to keep improving our product and game experience makes it a very rewarding job.</p>",
                name: "Pasqual Batalla",
                position: "Chief Operations Officer",
                city: "Barcelona",
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/team/yemin.jpg",
                  alt: "",
                },
                icon: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/quotes.svg",
                  alt: "",
                },
                htmlText:
                  "<p>At Socialpoint, my colleagues respect my feedback and I feel genuinely supported by my team. I like the fact that we work in an atmosphere of flexibility and creative freedom, where I keep learning new things and growing as a UI/UX designer. Plus, I have a really nice work-life balance! It’s funny to think I was a mere Monster Legends fan 5 years ago, and now I get to work for the company that created it! It’s destiny I guess… </p>",
                name: "Yemin (Rae) Sun",
                position: "UI/UX Designer",
                city: "Shanghai",
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/team/arnau.jpg",
                  alt: "",
                },
                icon: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/quotes.svg",
                  alt: "",
                },
                htmlText:
                  "<p>I chose Socialpoint because I was convinced that I could find interesting and challenging problems, from an analytics perspective. Given the huge amount of data that we deal with, it is the perfect place to learn and grow. What I like the most about my job is the wide variety of conundrums that we work with. We have many goals but time is limited, so we are always trying to find a solution that is good enough, fast. This is great because we are never stuck one any single long-term project. Every day is different!</p>",
                name: "Arnau Escapa",
                position: "Data Scientist",
                city: "Sabadell",
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/team/garazi.jpg",
                  alt: "",
                },
                icon: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/quotes.svg",
                  alt: "",
                },
                htmlText:
                  "<p>When I first stepped into its headquarters, I knew Socialpoint was my place! Years after, that feeling has nothing but increased. Socialpoint has not only given me the trust to set up the Global Mobility service but also the freedom to explore other fields that I was very interested in. <br> Here, I'm constantly learning and growing, and I understand how my job contributes to the business. The brilliant professionals I met when I joined are now friends too, and together, we cooperate, embrace new challenges, and of course, give it our all on the dancefloor at our legendary parties!</p>",
                name: "Garazi Zarraga",
                position: "Relocation Coordinator",
                city: "Bilbao",
              },
            ],
          },
        },
        {
          name: "FullWidthImage",
          props: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/meet_the_team/team_large.jpg",
            alt: "Team",
          },
        },
      ],
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "green",
      },
      components: [
        LifeAtSocialpoint,
        {
          name: "Separator",
          props: {
            topColor: "green",
            bottomColor: "gray",
            image: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/meet_the_team/meet_the_team_2.png",
              alt: "",
              position: "left",
              top: "-top-40 md:-top-500",
            },
          },
        },
      ],
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [JumpAboard],
    },
    Footer,
  ],
};

export default data;
