import footer from "./sections/Footer";
import Menu from "./sections/Menu";
import video from "../images/dynamic-header/careers.mp4";
import poster from "../images/dynamic-header/careers.jpg";

const data = {
  meta: {
    title: "Socialpoint Careers",
    description:
      "Socialpoint is always looking for the best. On this page, you'll find all our current vacancies ordered by job function.Whatever your talent, the next step in your career might be just a click away.",
    keywords:
      "socialpoint, social point, career, socialpoint career, socialpoint job openings, job in barcelona, mobile games career, f2p role, socialpoint vacancies"
  },
  menu: Menu(4),
  components: [
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray"
      },
      components: [
        {
          name: "SectionHeader",
          props: {
            title: "Careers",
            image:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/careers/careers_header.jpg",
            imageMobile: {
              url:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/careers/careers_header_portrait.jpg"
            },
            video: {
              src: video,
              poster: poster,
              player: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/player-play.svg",
                alt: ""
              },
              fullWidth: true,
              videoAsHeader: true
            }
          }
        },
        {
          name: "CareersOpenings",
          props: {
            htmlText: `<p>Socialpoint is always looking for the best. On this page, you’ll
              find all our current vacancies ordered by job function.</p> <p>Whatever your talent, the next step in your career might be just a click away.</p>`,
            cta: {
              hrefTo: "/join-us/job-openings",
              text: "View all openings"
            }
          }
        },
        {
          name: "CareersGrid",
          props: {
            title: "explore by job function",
            viewOffersText: "View offers",
            offersTextSingular: "offer",
            offersTextPlural: "offers",
            careers: [
              // {
              //   name: 'Analytics',
              //   offersNumber: 2,
              //   hrefTo: '',
              //   arrowsIcon: '//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg'
              // },
            ]
          }
        },
        {
          name: "FullWidthImage",
          props: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/careers/full-width.jpg",
            alt: "The people"
          }
        },
        footer
      ]
    }
  ]
};

export default data;
